<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-row class="mb-3">
          <v-col
            cols="12"
            sm="10"
            md="6"
            lg="4"
            xl="3"
            class="d-flex"
          >
            <v-text-field
              v-model="searchValue"
              :label="$t('Search...')"
              prepend-inner-icon="mdil-magnify"
              outlined
              dense
              clearable
              single-line
              hide-details=""
              :color="$_input_color()"
              autocomplete="off"
              :class="$_small_input()"
              @keydown.enter="handleSearch()"
            />

            <v-btn
              class="mx-2"
              :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
              small
              depressed
              @click="resetData()"
            >
              <v-icon left>
                mdil-refresh
              </v-icon>
              {{ $t('Reset') }}
            </v-btn>
          </v-col>

          <v-col
            v-if="hasAddNew && $_can(addPermission)"
            class="d-flex justify-end"
          >
            <div class="col-sm-4 col-md-5 col-lg-2 col-xl-2 pa-0">
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                return-object
                multiple
                dense
                solo
                chips
                flat
                prepend-inner-icon="mdil-table"
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3' "
                single-line
                hide-details
                class="has-prepend-inner small-input"
                :menu-props="{'offset-y': true}"
                @input="columnVisibilityChanged()"
              >
                <template v-slot:selection="{index}">
                  <span v-if="index === 0">
                    {{ selectedHeaders.length }}
                    {{ $t('of') }}
                    {{ headers.length }}
                  </span>
                </template>
              </v-select>
            </div>

            <v-btn
              class="ms-2"
              color="primary"
              small
              depressed
              @click="$emit('add-new')"
            >
              <v-icon left>
                mdil-plus
              </v-icon>
              {{ $t('Add New') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          class="app-datatable"
          :headers="visibleHeaders"
          :items="tableData.results"
          :loading="loadingTable"
          :server-items-length="tableData.count"
          :items-per-page.sync="queryParams.page_size"
          :no-results-text="$t('No results found.')"
          :no-data-text="$t('No results found.')"
          :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
          :item-class="rowColor"
          dense
          fixed-header
          :height="height"
          @click:row="handleRowClick"
          @update:sort-by="handelChangeSort"
          @update:sort-desc="handelChangeSortDir"
          @update:items-per-page="handelChangePerPage"
          @update:page="handelChangePage"
        >
          <template
            v-for="(slotName, index) in tableSlots"
            v-slot:[`item.${slotName}`]="{item}"
          >
            <div :key="index">
              <slot
                :name="slotName"
                :item="item"
              />
            </div>
          </template>
        </v-data-table>

        <!-- <app-pagination
          v-if="!loadingTable"
          :mini="true"
          :query-params="queryParams"
          :total-records="tableData.count"
        /> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    headers: { type: Array, default: () => [] },
    endPoint: { type: String, default: '' },
    height: { type: [String, Number], default: '550' },
    hasAddNew: { type: Boolean, default: true },
    addPermission: { type: String, default: 'abc' },
    resetTableData: { type: Boolean, default: false },
    updateTableData: { type: Boolean, default: false },
    tableSlots: { type: Array, default: () => [] },
    highlightRow: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedHeaders: [],
      visibleHeaders: [],
      searchValue: '',
      searchTimeout: null,
      loadingTable: true,
      tableData: {},
      totalRecords: 0,
      queryParams: {
        ordering: '-id',
        page: 1,
        page_size: 10,
        search: '',
      }
    }
  },

  computed: {
    ...mapGetters(['sectionTitle']),
    //  headerSlots() {
    //   return JSON.parse(JSON.stringify(this.headers))
    // },
  },

  watch: {
    headers() {
      this.getVisibleHeaderFromLocalStorage()
    },

    queryParams: {
      immediate: true,
      deep: true,
      handler() {
        this.getTableData()
      }
    },

    resetTableData: {
      immediate: false,
      handler() {
        if (this.resetTableData) {
          this.queryParams = {
            ordering: '-id',
            page: 1,
            page_size: 10,
            search: '',
          }
        }
      }
    },

    updateTableData: {
      immediate: false,
      handler() {
        if (this.updateTableData) {
          this.getTableData()
        }
      }
    },

    searchValue: {
      immediate: false,
      handler(newValue) {
        if (!newValue || newValue.length < 1) {
          this.handleSearch()
        } else {
          clearTimeout(this.searchTimeout)
          this.searchTimeout = setTimeout(() => {
            this.handleSearch(newValue)
          }, 500)
        }
      }
    },
  },

  mounted() {
    this.getVisibleHeaderFromLocalStorage()
  },

  methods: {
    rowColor(row) {
      if (this.highlightRow && row.min_quantity_alert && row.quantity <= row.min_quantity_alert) {
        return this.$vuetify.theme.dark ? 'error darken-1': 'error lighten-4'
      }
    },

    handleRowClick(val) {
      this.$emit('row-clicked', val)
    },

    resetData() {
      this.queryParams = {
        ordering: '-id',
        page: 1,
        page_size: 10,
        search: '',
      }
    },

    getVisibleHeaderFromLocalStorage() {
      let storedVisibleColumns = JSON.parse(localStorage.getItem(this.$_section_title_for_ls())) || []
      if (storedVisibleColumns.length) {
        this.selectedHeaders = storedVisibleColumns
        this.visibleHeaders = storedVisibleColumns
      } else {
        this.selectedHeaders = this.headers
        this.visibleHeaders = this.headers
      }
    },

    columnVisibilityChanged() {
      let visible = this.headers.filter(header => this.selectedHeaders.some(selectedHeader => selectedHeader.text == header.text))
      localStorage.setItem(this.$_section_title_for_ls(), JSON.stringify(visible))
      this.visibleHeaders = visible
    },

    getTableData() {
      this.loadingTable = true
      axios.get(this.endPoint, { params: this.queryParams }).then(res => {
        this.tableData = res.data
        this.$emit('data-loaded')
      }).finally(() => {
        this.loadingTable = false
      })
    },

    handelChangeSort(value) {
      this.queryParams.ordering = value
    },

    handelChangeSortDir(value) {
      if (value) {
        this.queryParams.ordering = `-${this.queryParams.ordering}`
      } else {
        this.queryParams.ordering = `${this.queryParams.ordering}`
      }
    },

    handleSearch() {
      this.queryParams.search = this.searchValue
    },

    handelChangePage(page) {
      this.queryParams.page = page
    },

    handelChangePerPage(perPage) {
      this.queryParams.page_size = perPage
    },
  },
}
</script>

<style lang="scss" scoped>
</style>