<template>
  <div>
    <tr>
      <th
        colspan="100"
        class="d-none d-print-table-cell text-center header-image-container"
      >
        <img
          src="../../assets/header-image.png"
          width="60%"
          height="85px"
          alt=""
        >
      </th>
    </tr>

    <tr>
      <th
        id="invoice-info"
        colspan="100"
        class="d-none d-print-table-cell"
        style="border-bottom: 1px solid #ccc"
      >
        <div class="d-flex justify-between">
          <div
            class="col pa-0"
            style="direction: ltr"
          >
            0773 728 7000
          </div>
          <div class="col pa-0">
            lorins2022@gmail.com
          </div>
          <div class="col pa-0">
            سلێمانی شەقامی مەلیک مەحموود تەنیشت مۆبیلیاتی بەیزا
          </div>
        </div>
      </th>
    </tr>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>