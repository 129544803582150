import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css';
import '@mdi/light-font/css/materialdesignicons-light.css';
import '@fortawesome/fontawesome-free/css/all.css'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  icons: {
    iconfont: 'mdi' || 'mdil' || 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#5062AD',
        // primary: '#2a2530',
        // secondary: '#8BDD21',
        // accent: '#8c9eff',
        // error: '#b71c1c'
      },
      dark: {
        primary: '#5062AD',
        // secondary: '#8BDD21',
        // accent: '#8c9eff'
        // error: '#b71c1c'
      },
    }
  }
})
