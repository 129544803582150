<template>
  <div>
    <v-autocomplete
      v-if="showProductSearch"
      id="app-item-search"
      v-model="selectedItem"
      :class="classes"
      :label="$t('Search item...')"
      prepend-inner-icon="mdil-magnify"
      :loading="loading"
      :items="itemsList"
      :search-input.sync="search"
      :readonly="readonly"
      return-object
      cache-items
      auto-select-first
      clearable
      outlined
      dense
      hide-no-data
      hide-details
      single-line
      @keypress.enter.prevent="searchForItems(search)"
      @change="handleChange(selectedItem)"
    />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    exist: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    classes: { type: String, default: '' },
    endpoint: { type: String, default: 'item/search/' },
  },

  data() {
    return {
      showProductSearch: true,
      search: null,
      selectedItem: null,
      loading: false,
      itemsList: [],
      searchTimeout: null,
      formData: {},
    }
  },

  watch: {
    search(val) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchForItems(val)
      }, 750)
    },
  },

  methods: {
    searchForItems(keyword) {
      if (!keyword) return

      let payload = {
        keyword: keyword,
        exist: this.exist,
      }

      axios.post(this.endpoint, payload).then(res => {
        if (res.data.length == 1) {
          this.$emit('selected', res.data[0])
          this.searchReset()
        }
        this.itemsList = res.data
      }).catch(() => {
        this.searchReset()
      })
    },

    searchReset() {
      this.selectedItem = ''
      this.showProductSearch = false
      this.$nextTick(() => {
        this.showProductSearch = true
        setTimeout(() => {
          document.getElementById('app-item-search').focus()
        }, 300)
      })
    },

    handleChange(item) {
      this.$emit('selected', item)
      this.searchReset()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>