<template>
  <v-app>
    <notification />

    <div v-if="isLoggedIn">
      <sidebar :drawer="drawer" />

      <topbar @toggle-drawer="drawer = !drawer" />

      <v-main>
        <v-container fluid>
          <transition
            :name="$vuetify.rtl ? 'slideRight' : 'slideLeft' "
            mode="out-in"
          >
            <router-view />
          </transition>
        </v-container>
      </v-main>
    </div>

    <div v-else>
      <login />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Login from './components/Layout/Login.vue'
import Sidebar from './components/Layout/Sidebar.vue'
import Topbar from './components/Layout/Topbar.vue'
import Notification from './components/Global/Notification.vue'
import axios from 'axios'

export default {
  name: 'App',

  components: {
    Sidebar,
    Topbar,
    Login,
    Notification
  },

  data() {
    return {
      drawer: true,
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn'])
  },

  beforeMount() {
    if (this.isLoggedIn) {
      const vuex = JSON.parse(localStorage.getItem('vuex'))
      this.$vuetify.rtl = vuex.auth.rtl
      this.$vuetify.theme.dark = vuex.auth.themeIsDark
      this.$i18n.locale = vuex.auth.locale

      axios.defaults.headers.common['Authorization'] = `Bearer ${vuex.auth.accessToken}`
    }
  },

  mounted() {
    const appWrap = document.querySelector('.v-application--wrap')
    appWrap.classList.add('app-background')
  }
}
</script>
<style lang="scss">
@import "../public/style.scss";

.slideLeft-enter-active,
.slideLeft-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slideLeft-enter,
.slideLeft-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.slideRight-enter-active,
.slideRight-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slideRight-enter,
.slideRight-leave-to {
  opacity: 0;
  transform: translateX(30%);
}
</style>
