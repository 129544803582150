<template>
  <div>
    <v-dialog
      v-model="confirmDialog"
      persistent
      transition="slide-y-transition"
      max-width="400"
    >
      <v-card class="dialog-card-border">
        <v-card-title
          class="text-h5 font-weight-light"
          :class="titleColor"
        >
          {{ $t(title) }}
        </v-card-title>

        <v-card-text>
          {{ $t(message) }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('cancel')"
          >
            {{ $t(cancelBtnText) }}
          </v-btn>

          <v-btn
            depressed
            small
            :color="confirmBtnColor"
            @click="$emit('confirm')"
          >
            <v-icon
              v-if="confirmBtnIcon.length > 0"
              small
              left
              class="fs-20"
            >
              {{ confirmBtnIcon }}
            </v-icon>

            {{ $t(confirmBtnText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',

  props: {
    confirmDialog: { type: Boolean, default: false },
    title: { type: String, default: 'Warning' },
    titleColor: { type: String, default: 'error--text' },
    message: { type: String, default: 'This action delete the data permanently.' },
    confirmBtnText: { type: String, default: 'Delete' },
    confirmBtnIcon: { type: String, default: 'mdil-delete' },
    confirmBtnColor: { type: String, default: 'error' },
    cancelBtnText: { type: String, default: 'Cancel' },
  },
}
</script>

<style lang="scss" scoped></style>
