import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/components/Home.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/components/User/Profile.vue')
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: () => import('@/components/User/Users.vue')
  // },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/components/Currency/Currencies.vue')
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/components/Supplier/Suppliers.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/components/Customer/Customers.vue')
  },
  {
    path: '/item',
    name: 'item',
    component: () => import('@/components/Item/Items.vue')
  },
  {
    path: '/expense-type',
    name: 'expense-type',
    component: () => import('@/components/ExpenseType/ExpenseTypes.vue')
  },
  {
    path: '/expense',
    name: 'expense',
    component: () => import('@/components/Expense/Expenses.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('@/components/Purchase/Purchases.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/components/Payment/Payments.vue')
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import('@/components/Sale/Sales.vue')
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: () => import('@/components/Receipt/Receipts.vue')
  },

  ///////////////////////// Reports 
  {
    path: '/report/profit',
    name: '/report/profit',
    component: () => import('@/components/Report/Profit.vue')
  },
  {
    path: '/report/supplier-actions',
    name: '/report/supplier.actions',
    component: () => import('@/components/Report/SupplierActions.vue')
  },
  {
    path: '/report/customer-actions',
    name: '/report/customer.actions',
    component: () => import('@/components/Report/CustomerActions.vue')
  },
  {
    path: '/report/dashboard',
    name: '/report/dashboard',
    component: () => import('@/components/Report/Dashboard.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
