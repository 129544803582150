<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      stateless
      :right="this.$vuetify.rtl"
      class="sidebar-navigation"
      width="170"
    >
      <v-list
        dense
        nav
      >
        <template v-for="item in sidebarItem">
          <v-subheader
            v-if="item.subHeader"
            :key="item.title"
            class="mt-2 mb-n3 font-weight-bold text--disabled"
          >
            {{ $t(item.title) }}
          </v-subheader>

          <v-list-item
            v-if="$_can(item.permission)"
            v-show="!item.children && !item.subHeader"
            :key="item.id"
            :to="item.path"
          >
            <v-list-item-icon class="me-3">
              <v-icon color="primary">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="$_can(item.permissions)"
            v-show="item.children"
            :key="item.id"
            v-model="item.model"
            :append-icon="item.iconalt"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-3">
                <v-icon color="primary">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </template>

            <template v-for="child in item.children">
              <v-list-item
                :key="child.id"
                :to="child.path"
                link
              >
                <v-list-item-content class="ps-10">
                  <v-list-item-title>
                    {{ $t(child.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    drawer: { type: Boolean, default: true },
  },

  data() {
    return {
      sidebarItem: [
        {
          title: 'Items',
          icon: 'mdil-script',
          path: '/item',
          permission: 'view_item',
        },
        {
          title: 'Purchases',
          icon: 'mdil-cart',
          path: '/purchase',
          permission: 'view_purchase',
        },
        {
          title: 'Payments',
          icon: 'mdil-note-text',
          path: '/payment',
          permission: 'view_payment',
        },
        {
          title: 'Sales',
          icon: 'mdil-currency-usd',
          path: '/sale',
          permission: 'view_sale',
        },
        {
          title: 'Receipts',
          icon: 'mdil-tooltip-text',
          path: '/receipt',
          permission: 'view_receipt',
        },
        {
          title: 'Expenses',
          icon: 'mdil-currency-gbp',
          path: '/expense',
          permission: 'view_expense',
        },
        {
          title: 'Definitions',
          subHeader: true
        },
        {
          title: 'Suppliers',
          icon: 'mdil-account',
          path: '/supplier',
          permission: 'view_supplier',
        },
        {
          title: 'Customers',
          icon: 'mdil-account',
          path: '/customer',
          permission: 'view_customer',
        },
        {
          title: 'Expense Types',
          icon: 'mdil-layers',
          path: '/expense-type',
          permission: 'view_expensetype',
        },
        // {
        //   title: 'Currencies',
        //   icon: 'mdil-currency-eur',
        //   path: '/currency',
        //   permission: 'view_currency',
        // },
        // {
        //   title: 'Users',
        //   icon: 'mdil-account',
        //   path: '/user',
        //   permission: 'view_user',
        // },
        {
          title: 'Reports',
          subHeader: true
        },
        // {
        //   title: 'Dashboard',
        //   icon: 'mdil-view-dashboard',
        //   path: '/report/dashboard',
        //   permission: 'view_dashboard',
        // },
        {
          title: 'Profit',
          icon: 'mdil-chart-bar',
          path: '/report/profit',
          permission: 'view_profit',
        },
        {
          title: 'Supplier Actions',
          icon: 'mdil-chart-bar',
          path: '/report/supplier-actions',
          permission: 'view_supplier_actions',
        },
        {
          title: 'Customer Actions',
          icon: 'mdil-chart-bar',
          path: '/report/customer-actions',
          permission: 'view_customer_actions',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// a.v-list-item--active {
//   background-color: #f1e3e7 !important;
// }
</style>
