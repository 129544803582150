<template>
  <div>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
        class="mt-8"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded-lg pt-3 pb-5 px-4"
        >
          <v-card-title class="text-h6 font-weight-light text-center d-block">
            <div class="login-image-container mb-4">
              <img
                src="../../assets/header-image.png"
                width="100%"
                height="100%"
                alt=""
                style="object-fit: contain"
              >
            </div>
            <div>
              {{ $t('Login to your account') }}
            </div>
          </v-card-title>
          <validation-observer
            ref="theForm"
            v-slot="{ handleSubmit, reset }"
          >
            <form
              autocomplete="off"
              @submit.prevent="handleSubmit(submit)"
              @reset.prevent="reset"
            >
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Username')"
                  rules="required"
                >
                  <v-text-field
                    v-model="form.username"
                    append-icon="mdil-account"
                    :label="$t('Username')"
                    outlined
                    dense
                    :error-messages="errors"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Password')"
                  rules="required"
                >
                  <v-text-field
                    v-model="form.password"
                    :label="$t('Password')"
                    :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    outlined
                    dense
                    :error-messages="errors"
                    @click:append="show1 = !show1"
                  />
                </validation-provider>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  style="width: 50%"
                >
                  {{ $t('Login') }}
                  <v-icon right>
                    mdil-arrow-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      show1: false,
      form: {
        username: '',
        password: ''
      },
    }
  },

  methods: {
    ...mapActions([
      'login',
      'getAuthUserData',
      'getAuthUserPermission',
    ]),

    submit() {
      this.login(this.form).then(() => {
        this.getAuthUserData()
        this.getAuthUserPermission()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login-image-container {
  width: 100%;
  height: 120px;
}
</style>