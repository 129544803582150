import axios from 'axios'
import store from '../store'
import i18n from './i18n'

axios.defaults.baseURL = 'https://lorinscollection.com/api/'

// Handle error
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.request.status >= 400 && error.request.status <= 499) {
      if (error.response.data.message) {
        store.commit('SET_NOTIFICATION', {
          message: i18n.t(error.response.data.message),
          type: 'error',
          timeout: 5000
        })
      } else {
        let errors = ''
        for (const i in error.response.data) {
          errors +=
            '<li style="padding-top: 1px; padding-bottom: 1px">' +
            i +
            '<strong>:</strong> ' +
            error.response.data[i] +
            '</li>'
        }
        store.commit('SET_NOTIFICATION', {
          message: i18n.t(errors),
          type: 'error',
          timeout: 5000
        })
      }
    } else {
      store.commit('SET_NOTIFICATION', {
        message: i18n.t('Server error, please try again'),
        type: 'error',
        timeout: 3000
      })
    }
    return Promise.reject(error)
  }
)
